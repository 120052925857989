<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import axios from 'axios';
import html2pdf from "html2pdf.js";
export default {
  page: {
    title: "Profit & Loss Report",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Profit & Loss Report",
      items: [{
        text: "Velzon",
        href: "/",
      },
      {
        text: "Profit & Loss Report",
        active: true,
      },
      ],
      amount: 875,
      billing_address: "305 S San Gabriel Blvd, California, United States - 91776",
      billing_name: "David Nichols",
      billing_phone: "+(123) 456-7890",
      billing_taxno: "12-3456789",
      card_holder_name: "David Nichols",
      card_number: "xxx xxxx xxxx 1234",
      company_address: "403 H Fan Faranh Blvd, London, United States",
      contact: 8987894122,
      country: "Brazil",
      date: "2021-04-06T16:28:00.000Z",
      discount: "53.99",
      email: "velzon@themesbrand.com",
      estimated_tax: "44.99",
      img: "assets/images/users/avatar-2.jpg",
      invoiceId: "#VL25000351",
      name: "Diana Kohler",
      notes: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.",
      payment_method: "Mastercard",
      postalcode: 91798,
      product_detail: [{
        name: 'Urban Ladder Pashe Chair',
        category: 'Furniture',
        price: '160',
        stock: '2'
      }, {
        name: '350 ml Glass Grocery Container',
        category: 'Kitchen Storage & Containers',
        price: '39.96',
        stock: '1'
      }],
      registration_no: "6561",
      shipping_address: "345 Elm Ave, Solvang California, United States - 91776",
      shipping_charge: "65.00",
      shipping_name: "Donald Palmer",
      shipping_phone: "+(234) 987-01234",
      shipping_taxno: "12-3456789",
      status: "Paid",
      sub_total: 359.96,
      total_amount: 415.96,
      website: "www.themesbrand.com",
      ispdfgenerating: false,
      from_date: this.$route.params.from_date,
      to_date: this.$route.params.to_date,
      income: [],
      expense: [],
      total_income: 0,
      total_expense: 0,
    };
  },

  methods: {
    async getData() {

      var result = await axios.post(appConfig.api_url + 'project/PLReport', {
        from_date: this.from_date,
        to_date: this.to_date
      })

      //   alert(JSON.stringify(result.data))
      this.income = result.data.income;
      this.expense = result.data.expense;
      for (var i = 0; i < this.income.length; i++) {
        this.total_income += parseInt(this.income[i].payment_recieved);
      }

      for ( i = 0; i < this.expense.length; i++) {
        this.total_expense += parseInt(this.expense[i].amount);
      }
      //alert(this.total_expense)


      // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
      //   "Nov", "Dec"
      // ];

    }, async exportToPDF() {
      this.ispdfgenerating = true;

      var content = document.getElementById("element-to-convert");


      await html2pdf(content, {
        margin: 0.3,
        filename: "invoice-" + this.invoiceId + ".pdf",
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: {
          scale: 2
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait'
        }
      });
      this.ispdfgenerating = false;

    }, hasGenerated() {
      alert("PDF generated successfully!");

    }
  },
  mounted() {

    this.getData();
    // alert(appConfig.api_url + 'getpayment==' + this.$route.params.id);



    //  data = data.data.data
    // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
    //   "Nov", "Dec"
    // ];
    // var dd = new Date(data.date)
    // data.date = dd.getDate() + " " + monthNames[dd.getMonth()] + ", " + dd.getFullYear();
    // var hours = dd.getHours();
    // var minutes = dd.getMinutes();
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // hours = hours < 10 ? '0' + hours : hours;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // data.time = strTime;

    // this.company_address = data.company_address;
    // this.postalcode = data.postalcode;
    // this.registration_no = data.registration_no;
    // this.email = data.email;
    // this.website = data.website;
    // this.contact = data.contact;
    // this.invoiceId = data.invoiceId;
    // this.product_detail = data.product_detail;
    // this.date = data.date;
    // this.time = data.time;
    // this.status = data.status;

    // var itemclass = data.status == 'Paid' ? 'badge-soft-success' : data.status == 'Cancel' ?
    //   'badge-soft-danger' : data.status == 'Unpaid' ? 'badge-soft-warning' : data.status ==
    //   'Refund' ? 'badge-soft-primary' : '';
    // document.getElementById('payment-status').classList.add(itemclass);

    // this.total_amount = data.total_amount;

    // this.billing_name = data.billing_name;
    // this.billing_address = data.billing_address;
    // this.billing_phone = data.billing_phone;
    // this.billing_taxno = data.billing_taxno;

    // this.shipping_name = data.shipping_name;
    // this.shipping_address = data.shipping_address;
    // this.shipping_phone = data.shipping_phone;
    // this.shipping_taxno = data.shipping_taxno;

    // this.payment_method = data.payment_method;
    // this.card_holder_name = data.card_holder_name;
    // this.card_number = data.card_number;
    // this.total_amount = data.total_amount;
    // this.notes = data.notes;
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center" style="background-color:white">
      <div class="col-xxl-9">
        <div class="card" id="element-to-convert">
          <div class="card-header border-bottom-dashed p-4">
            <div class="d-sm-flex">
              <h3 class="text-center " style="padding-left:40%">Profit & Loss Report</h3>

            </div>
          </div>
          <!--end card-header-->
          <div class="card-body p-4">
            <div class="row g-3">
              <div class="col-lg-2 col-6">
                <p class="text-muted mb-2 text-uppercase fw-semibold">From Date</p>
                <h5 class="fs-14 mb-0"><span id="invoice-date">{{ from_date }}</span> </h5>
              </div>

              <div class="col-lg-2 col-6">
                <p class="text-muted mb-2 text-uppercase fw-semibold">To Date</p>
                <h5 class="fs-14 mb-0"><span id="invoice-date">{{ to_date }}</span> </h5>
              </div>

            </div>

          </div>
          <!--end card-body-->

          <div class="card-body p-4">
            <div class="">
              <h4>Income</h4>
              <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                <thead>
                  <tr class="table-active">
                    <th scope="col" style="width: 50px;">#</th>
                    <th scope="col" class="text-start">Name</th>
                    <th scope="col" class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody id="products-list">
                  <tr v-for="(item, index) of income" :key="index">
                    <th scope="row">{{ index }}</th>
                    <td class="text-start">
                      <span class="fw-medium">{{ item.income_head }}</span>

                    </td>
                    <td class="text-end">Rs.{{ item.payment_recieved }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="row text-end mt-2"  style="background-color:white;margin-left:-20px">
                <span style="margin-left:-10px;font-size:14px;font-weight: bold;">Total Income: Rs.{{ total_income }}  </span>
              </div>

              <h4>Expense</h4>
              <table class="table  text-center table-nowrap align-middle mb-0">
                <thead>
                  <tr class="table-active">
                    <th scope="col" style="width: 50px;">#</th>
                    <th scope="col" class="text-start">Name</th>
                    <th scope="col" class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody id="products-list">
                  <tr v-for="(item, index) of expense" :key="index">
                    <th scope="row">{{ index }}</th>
                    <td class="text-start">
                      <span class="fw-medium">{{ item.expense_head }}</span>

                    </td>
                    <td class="text-end">Rs.{{ item.amount }}</td>
                  </tr>
                </tbody>
              </table>
              <!--end table-->
            </div>
            <div class="row text-end mt-2" style="background-color:white">
              
              <span  style="margin-left:-10px;font-size:14px;font-weight: bold;">Total Expense: Rs.{{ total_expense }}</span> 
              <span  style="margin-left:-10px;font-size:14px;font-weight: bold;">Total Income: Rs.{{ total_income }}</span>
              <span  style="margin-left:-10px;font-size:14px;font-weight: bold;">Total P/L: Rs.{{ total_expense - total_income }}</span> 
              </div>

          </div>
          <!--end card-body-->
        </div>
        <div class="hstack gap-2 justify-content-end d-print-none mt-4">
          <a href="javascript:window.print()" class="btn btn-success"><i class="ri-printer-line align-bottom me-1"></i>
            Print</a>
          <a @click="exportToPDF" class="btn btn-primary"><i class="ri-download-2-line align-bottom me-1"></i>
            {{ ispdfgenerating? 'Generating...': 'Download PDF' }} </a>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>